
import { Component, Vue, Watch, Provide } from "vue-property-decorator"
import AppPage from "@/components/layout/AppPage.vue"
import SearchInput from "@/components/widgets/common/SearchInput.vue"
import SortableHandle from "@/components/widgets/sortable/SortableHandle.vue"
import SortableItem from "@/components/widgets/sortable/SortableItem.vue"
import ScopeServiceForm from "@/components/forms/ScopeServiceForm.vue"
import TagChip from "@/components/widgets/common/TagChip.vue"
import ScopeLibraryTagBar from "@/components/scopeLibrary/ScopeLibraryTagBar.vue"
import Loader from "@/components/widgets/common/Loader.vue"
import {
  ScopeServicesListQuery,
  ScopeService,
  ScopeServiceQuery,
  AllScopeLibraryPhasesQuery,
  ScopeLibraryPhase,
  ScopeServiceGroup,
  ScopePhaseSubscription,
} from "@/gql"
import { NetworkStatus } from "@apollo/client/core"
import PhaseList from "@/components/scopeLibrary/PhaseList.vue"
import ViewToggle from "@/components/widgets/common/ViewToggle.vue"
import Icon from "@/components/widgets/icons/Icon.vue"
import ServiceAttributesCard from "@/components/scopeLibrary/ServiceAttributesCard.vue"

type PageState = "loading" | "loaded" | "error" | "empty"
@Component({
  components: {
    AppPage,
    SearchInput,
    SortableItem,
    SortableHandle,
    ScopeServiceForm,
    TagChip,
    ScopeLibraryTagBar,
    Loader,
    PhaseList,
    ViewToggle,
    Icon,
    ServiceAttributesCard,
  },

  apollo: {
    serviceGroups: {
      query: ScopeServicesListQuery,
      result(result) {
        if (result.networkStatus === NetworkStatus.ready) {
          if (result.data?.scopeServiceGroups?.length) {
            const firstService = result.data?.scopeServiceGroups[0].services[0]
            if (firstService) {
              if (!this.selectedServiceId) this.setSelectedServiceId(firstService.id)
            } else {
              this.pageState = "empty"
            }

            this.cachedServiceGroups = result.data?.scopeServiceGroups
          } else {
            this.pageState = "empty"
          }

          this.loadingServices = false
        }
      },
      error(error) {
        if (error.graphQLErrors) {
          this.loadingServices = false
          this.loadingError = true
        } else if (error.networkError) {
          this.networkError = true
        }
      },
      update(data) {
        return data?.scopeServiceGroups || []
      },
    },

    allExistingPhases: {
      query: AllScopeLibraryPhasesQuery,

      result(result) {
        if (result.networkStatus === NetworkStatus.ready) {
          this.loadingServices = false
        }
      },
      error(error) {
        if (error.graphQLErrors) {
          this.loadingServices = false
          this.loadingError = true
        } else if (error.networkError) {
          this.networkError = true
        }
      },
      update(data) {
        return data?.scopeLibraryPhases || []
      },
    },

    service: {
      query: ScopeServiceQuery,
      variables() {
        return { id: this.selectedServiceId }
      },
      skip: true,
      result(result) {
        if (result.networkStatus === NetworkStatus.ready) {
          this.selectedScopeService = result.data?.scopeServices[0]

          if (this.selectedScopeService) {
            this.pageState = "loaded"
            this.cachedScopeService = JSON.parse(JSON.stringify(this.selectedScopeService))
          } else {
            this.pageState = "error"
          }

          this.loadingService = false
        }
      },
      error(error) {
        if (error.graphQLErrors) {
          this.loadingService = false
          this.loadingError = true
        } else if (error.networkError) {
          this.networkError = true
        }
      },
      update(data) {
        return data?.scopeServices[0] || null
      },
    },
  },
})
export default class ScopeLibrary extends Vue {
  loadingError = false
  networkError = false

  loadingServices = false
  loadingService = false
  saving = false
  saved = false

  error = false
  _timeout: number | undefined
  scopeServiceQuery = ScopeServiceQuery

  service!: ScopeService | null
  selectedScopeService: ScopeService | null = null
  selectedServiceId: number | null = null
  cachedScopeService: ScopeService | null = null
  cachedServiceGroups: ScopeServiceGroup[] = []

  allExistingPhases: ScopeLibraryPhase[] = []

  isSearchPhasesEmpty = false
  isSearchServicesEmpty = false

  serviceGroups: any = []
  editMode = false

  form = {
    serviceSearch: "",
    phaseSearch: "",
  }

  pageState: PageState = "loading"

  closeServiceFormModal() {
    this.showAddService = false
    this.editMode = false
  }

  get isScopeInputEditable() {
    return this.policy.canManageScopeLibrary
  }

  fullTextSearch(text: string, search: string) {
    const pattern = new RegExp(`.*${search}.*`, "i")
    return pattern.test(text)
  }

  searchPhasesAndActivities(searchTerm: string) {
    const source = JSON.parse(JSON.stringify(this.cachedScopeService)) as ScopeService

    return source.phaseSubscriptions.filter((s) => {
      const matchingActivities = s.activities.filter((a) => this.fullTextSearch(a.name, searchTerm))

      return matchingActivities.length || this.fullTextSearch(s.phase.name, searchTerm)
    })
  }

  searchServices(searchTerm: string) {
    const source = JSON.parse(JSON.stringify(this.cachedServiceGroups)) as ScopeServiceGroup[]

    return source.filter((serviceGroup: ScopeServiceGroup) => {
      const matchingServices = serviceGroup.services.filter(
        (service) =>
          this.fullTextSearch(service.name, searchTerm) ||
          this.fullTextSearch(service.description, searchTerm)
      )

      return matchingServices.length
    })
  }

  @Watch("form.phaseSearch")
  onPhaseSearchChange() {
    // Return if there are no scope phases
    if (!this.cachedScopeService?.phaseSubscriptions.length) return

    let phaseSubscriptions: ScopePhaseSubscription[] = []

    if (this.cachedScopeService && !this.form.phaseSearch) {
      phaseSubscriptions = this.cachedScopeService.phaseSubscriptions
    } else {
      phaseSubscriptions = this.searchPhasesAndActivities(
        this.form.phaseSearch
      ) as ScopePhaseSubscription[]
    }

    this.isSearchPhasesEmpty = !phaseSubscriptions.length

    this.selectedScopeService = {
      ...this.selectedScopeService,
      phaseSubscriptions: [...phaseSubscriptions],
    } as ScopeService
  }

  @Watch("form.serviceSearch")
  onServicesSearchChange() {
    let searchResults: ScopeServiceGroup[] = []

    if (!this.form.serviceSearch) {
      searchResults = this.cachedServiceGroups
    } else {
      searchResults = this.searchServices(this.form.serviceSearch)
    }

    this.isSearchServicesEmpty = !searchResults.length
    this.serviceGroups = [...searchResults]
  }

  async setSelectedServiceId(id: number) {
    this.selectedServiceId = id
    await this.fetchScopeService(this.selectedServiceId)
  }

  onAddNewService() {
    this.showAddService = true
  }

  @Provide()
  allPhases() {
    return this.allExistingPhases
  }

  @Provide()
  get policy() {
    return {
      canManageScopeLibrary: this.can("manage_scope_library"),
    }
  }

  onTabNavigation(id: number) {
    this.setSelectedServiceId(id)
  }

  async onRetryRequest() {
    this.$router.go(0)
  }

  async fetchScopeService(id: number | null = null) {
    if (!id) return

    this.loadingService = true
    this.$apollo.queries.service.skip = false
    this.$apollo.queries.service.refetch()
  }

  onSaving(): void {
    this.saving = true
  }

  onSaved(): void {
    this.saved = true
    this.saving = false
    clearTimeout(this._timeout)
    this._timeout = window.setTimeout(() => {
      this.saved = false
    }, 4000)
  }

  onError(): void {
    this.error = true
    this.saving = false
    clearTimeout(this._timeout)

    this._timeout = window.setTimeout(() => {
      this.error = false
    }, 4000)
  }

  async onServiceSave() {
    this.showAddService = false
  }

  showTabsPanel = 0
  showAddService = false

  onAddNewPhase() {
    this.$refs.PhaseList && (this.$refs.PhaseList as PhaseList).onAddNewPhase()
  }
}
