
import { Component, Prop, Vue, Watch, Inject } from "vue-property-decorator"
import {
  ScopePhaseSubscription,
  AllScopeLibraryPhasesQuery,
  ScopeServiceQuery,
  DeleteScopeLibraryActivityMutation,
  DeleteScopeLibraryActivityMutationMutation,
  ScopeLibraryPhase,
  ScopeLibraryActivity,
  UpdateActivityPositionMutationMutation,
  UpdateActivityPositionMutation,
  DeleteScopePhaseSubscriptionMutation,
  DeleteScopePhaseSubscriptionMutationMutation,
  UpdatePhaseSubscriptionPositionMutation,
  UpdatePhaseSubscriptionPositionMutationMutation,
} from "@/gql"
import SortableHandle from "@/components/widgets/sortable/SortableHandle.vue"
import SortableItem from "@/components/widgets/sortable/SortableItem.vue"
import SortableList from "@/components/widgets/sortable/SortableList.vue"
import PhaseInput from "./PhaseInput.vue"
import ActivityInput from "./ActivityInput.vue"

export type ScopePhaseActivityType = Partial<ScopeLibraryActivity> & {
  tempId?: string
  scopeLibraryPhaseId?: BigInt
  saved?: boolean
}

export type ScopePhaseType = Partial<Omit<ScopeLibraryPhase, "activities">> & {
  activities: ScopePhaseActivityType[]
  position?: BigInt
  tempId?: string
  phaseSubscriptionId?: BigInt
  saved?: boolean
}

@Component({
  components: {
    SortableList,
    SortableItem,
    SortableHandle,
    PhaseInput,
    ActivityInput,
  },
})
export default class PhaseList extends Vue {
  @Prop({ required: true }) readonly scopeServiceId!: BigInt
  @Prop() readonly phaseSubscriptions!: ScopePhaseSubscription[]
  @Prop({ default: true }) readonly shouldSaveChanges?: boolean
  @Prop({ default: true }) readonly isEditable?: boolean
  @Prop({ default: true }) readonly showSubscriptionCount?: boolean
  @Prop({ default: false }) readonly expandPhasePanel?: boolean

  @Inject() readonly policy!: Record<any, boolean>

  activeIndex: number | null = null
  allScopeLibraryPhasesQuery = AllScopeLibraryPhasesQuery
  scopeServiceQuery = ScopeServiceQuery
  showDeleteDialog = false
  showPhaseDeleteDialog = false
  selectedActivity: ScopePhaseActivityType | null = null
  selectedPhase: ScopePhaseType | null = null
  deleting = false
  phaseExpansionPanelState: Record<string, any> = {}

  onPhaseSaved(phase: ScopePhaseType) {
    if (phase) {
      this.updatePhaseId(phase)
    }
    this.$emit("saved")
  }

  onPhaseActivitySaved(phase: ScopePhaseType, activity: ScopePhaseActivityType) {
    const currentActivity = phase.activities.find((a) => a.tempId === activity.tempId)

    if (currentActivity && !currentActivity.id) {
      this.$set(currentActivity, "id", activity.id)
      this.$set(currentActivity, "saved", true)
    }
    this.$emit("saved")
  }

  onSaving() {
    this.$emit("saving")
  }

  onError() {
    this.$emit("error")
  }

  async updatePhasePosition(phase: ScopePhaseType, newIndex: number) {
    if (!this.shouldSaveChanges) return

    this.$emit("saving")
    const result = await this.mutate<UpdatePhaseSubscriptionPositionMutationMutation>({
      mutation: UpdatePhaseSubscriptionPositionMutation,
      variables: {
        position: newIndex,
        id: phase.phaseSubscriptionId,
      },
    })
    if (result.data?.updateScopePhaseSubscription?.error) {
      this.$emit("error")
      return
    }
    this.$emit("saved")
  }

  onPhaseSort(phases: ScopePhaseType[], { newIndex }: { [key: string]: any }) {
    this.updatePhasePosition(phases![newIndex], newIndex)
  }

  async updateActivityPosition(activity: ScopePhaseActivityType, newIndex: number) {
    if (!this.shouldSaveChanges) return

    this.$emit("saving")
    const result = await this.mutate<UpdateActivityPositionMutationMutation>({
      mutation: UpdateActivityPositionMutation,
      variables: {
        position: newIndex,
        id: activity.id,
      },
    })
    if (result.data?.updateScopeLibraryActivity?.error) {
      this.$emit("error")
      return
    }
    this.$emit("saved")
  }

  onActivitySort(phase: ScopePhaseType, { newIndex }: { [key: string]: any }) {
    const activities = phase.activities
    this.updateActivityPosition(activities![newIndex], newIndex)
  }

  refetchQueries = [
    {
      query: this.scopeServiceQuery,
      variables: { id: this.scopeServiceId },
    },
    {
      query: this.allScopeLibraryPhasesQuery,
    },
  ]

  defaultPhaseActivity: ScopePhaseActivityType = {
    name: "",
    id: null,
    tempId: this.generateRand(15),
    saved: false,
  }

  defaultPhase: ScopePhaseType = {
    name: "",
    id: null,
    activities: [],
    tempId: this.generateRand(10),
  }

  phases: ScopePhaseType[] = []

  //This is a hack to force the expansion panel to open
  openExpansionPanel() {
    this.phases.forEach((_, index) => {
      this.$set(this.phaseExpansionPanelState, index, 0)
    })
  }

  @Watch("phases", { deep: true })
  onPhasesChange() {
    this.$emit("change", this.phases)
  }

  normalisePhaseActivities(activities: ScopePhaseActivityType[]) {
    return activities.map((activity) => ({
      ...activity,
      tempId: this.generateRand(15),
      saved: true,
    }))
  }

  @Watch("phaseSubscriptions", { immediate: true, deep: true })
  populateForm() {
    const unsavedPhases = this.phases.filter((phase) => !phase.saved && !phase.name)

    this.phases = [
      ...this.phaseSubscriptions.map((s) => ({
        ...s.phase,
        phaseSubscriptionId: s.id,
        position: s.position,
        activities: this.normalisePhaseActivities(s.activities),
        tempId: this.generateRand(10),
        saved: true,
      })),
      ...unsavedPhases,
    ]
  }

  onAddNewPhase() {
    if (this.phases.length && this.phases[this.phases.length - 1].name === "") return

    this.phases.push({
      ...this.defaultPhase,
      tempId: this.generateRand(10),
    })
  }

  onAddNewActivity(phase: ScopePhaseType) {
    if (!phase.saved) return

    let timeoutId
    //check if last phase has name or phase has no activities to prevent adding empty activities
    if (phase.activities.length && phase.activities[phase.activities.length - 1].name === "") {
      const target = (this.$refs[`ActivityInput${phase.tempId}`] as any[])[
        phase.activities.length - 1
      ]

      if (target && typeof target.setInputError === "function") {
        target.setInputError()
      }
      clearTimeout(timeoutId)
      timeoutId = setTimeout(() => {
        target.unSetInputError()
      }, 700)
      return
    }

    phase.activities.push({
      ...this.defaultPhaseActivity,
      tempId: this.generateRand(15),
    })
  }

  updatePhaseId(phase: ScopePhaseType) {
    const currentPhase = this.phases.find((p) => p.tempId === phase.tempId)
    if (currentPhase) {
      this.$set(currentPhase, "id", phase.id)
      this.$set(currentPhase, "saved", true)
    }
  }

  removeActivity(phase: ScopePhaseType, activity: ScopePhaseActivityType) {
    const index = phase.activities.findIndex((a) => a.tempId === activity.tempId)
    if (index > -1) {
      phase.activities.splice(index, 1)
    }
  }

  onDeletePhaseActivity(phase: ScopePhaseType, activity: ScopePhaseActivityType) {
    this.selectedActivity = activity
    this.selectedPhase = phase
    if (activity.saved && this.shouldSaveChanges) {
      this.showDeleteDialog = true
    } else {
      this.removeActivity(phase, activity)
    }
  }

  async deleteSavedPhase() {
    if (!this.selectedPhase) return
    this.deleting = true

    const result = await this.mutate<DeleteScopePhaseSubscriptionMutationMutation>({
      mutation: DeleteScopePhaseSubscriptionMutation,
      variables: {
        id: this.selectedPhase.phaseSubscriptionId,
      },
      refetchQueries: [
        {
          query: this.scopeServiceQuery,
          variables: { id: this.scopeServiceId },
        },
      ],

      done: () => (this.deleting = false),
    })

    if (!result?.data?.deleteScopePhaseSubscription.error) {
      this.deleting = false
      this.addSuccess("Phase deleted")
      this.showPhaseDeleteDialog = false
    }
  }

  onDeletePhaseSubscription(phase: ScopePhaseType) {
    this.selectedPhase = phase
    if (phase.saved && this.shouldSaveChanges) {
      this.showPhaseDeleteDialog = true
    } else {
      const index = this.phases.findIndex((p) => p.tempId === phase.tempId)
      if (index > -1) {
        this.phases.splice(index, 1)
      }
    }
  }

  async deleteSavedActivity() {
    if (!this.selectedActivity) return
    this.deleting = true

    const result = await this.mutate<DeleteScopeLibraryActivityMutationMutation>({
      mutation: DeleteScopeLibraryActivityMutation,
      variables: {
        id: this.selectedActivity.id,
      },
      refetchQueries: [
        {
          query: this.scopeServiceQuery,
          variables: { id: this.scopeServiceId },
        },
      ],

      done: () => (this.deleting = false),
    })

    if (!result?.data?.deleteScopeLibraryActivity.error) {
      this.deleting = false
      this.addSuccess("Phase activity deleted")
      this.showDeleteDialog = false
      //delete activity from phases
      this.removeActivity(this.selectedPhase!, this.selectedActivity)
    }
  }

  mounted() {
    if (this.expandPhasePanel) {
      this.openExpansionPanel()
    }
  }
}
