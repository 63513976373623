
import { Component, Prop, Vue, Emit, Watch } from "vue-property-decorator"
import {
  CreateScopeLibraryActivityMutation,
  CreateScopeLibraryActivityMutationMutation,
  UpdateScopeLibraryActivityMutation,
  UpdateScopeLibraryActivityMutationMutation,
} from "@/gql"
import { ScopePhaseActivityType, ScopePhaseType } from "@/components/scopeLibrary/PhaseList.vue"
import { RefetchQueryDescription } from "apollo-client/core/watchQueryOptions"

@Component({})
export default class ActivityInput extends Vue {
  @Prop() readonly refetchQueries?: RefetchQueryDescription
  @Prop() readonly activity!: ScopePhaseActivityType
  @Prop() readonly phase!: ScopePhaseType
  @Prop() readonly value!: string
  @Prop({ default: true }) readonly shouldSaveChanges?: boolean
  @Prop({ default: true }) readonly isEditable?: boolean

  form: { name: string | null } = { name: "" }
  width: string | null = null

  @Watch("activity", { immediate: true, deep: true })
  onActivityChange() {
    this.form.name = this.activity.name || null
  }

  inputError = false

  setInputError() {
    this.inputError = true
  }

  unSetInputError() {
    this.inputError = false
  }

  @Emit("saving")
  emitSaving() {
    return true
  }

  onChange() {
    this.$emit("input", this.form.name)
    if (!this.form.name) return (this.inputError = true)

    if (this.shouldSaveChanges) {
      if (this.activity && this.activity.saved) this.updateActivity()
      else this.createActivity()
    }
  }

  @Watch("form.name")
  onInputChange() {
    this.setWidth()
  }

  setWidth() {
    const el = this.$el as HTMLElement

    if (!el || !this.form.name) return

    const pw = el.parentElement?.closest(".activity-input-wrap") as HTMLElement
    const sh = pw && (pw.querySelector(".sortable-handle") as HTMLElement)
    const tw = pw && (pw.querySelector(".tools-wrap") as HTMLElement)

    const availableWidth = pw && tw && pw.offsetWidth - (sh?.offsetWidth ?? 0) - 45

    this.width = this.form.name.trim().length + "ch"

    this.$nextTick(() => {
      if (el.offsetWidth > availableWidth) {
        this.width = availableWidth + "px"
        return
      }
    })
  }

  async createActivity() {
    this.emitSaving()
    const { name } = this.form

    const result = await this.mutate<CreateScopeLibraryActivityMutationMutation>({
      mutation: CreateScopeLibraryActivityMutation,
      variables: {
        name,
        scopePhaseSubscriptionId: this.phase.phaseSubscriptionId,
      },
      refetchQueries: this.refetchQueries,
      error: () => this.$emit("error"),
    })

    if (result.data?.createScopeLibraryActivity?.scopeLibraryActivity) {
      this.$emit("saved", {
        ...result.data?.createScopeLibraryActivity?.scopeLibraryActivity,
        tempId: this.activity.tempId,
      })
    }
  }

  async updateActivity() {
    this.emitSaving()
    const { name } = this.form

    await this.mutate<UpdateScopeLibraryActivityMutationMutation>({
      mutation: UpdateScopeLibraryActivityMutation,
      variables: {
        name,
        id: this.activity.id,
      },
      update: (cache, { data }) => {
        if (data?.updateScopeLibraryActivity?.error) {
          this.$emit("error")
          return
        }
        this.$emit("saved", {
          ...data?.updateScopeLibraryActivity?.scopeLibraryActivity,
          tempId: this.activity.tempId,
        })
      },
    })
  }

  mounted() {
    setTimeout(() => {
      this.setWidth()
    }, 100)

    if (!this.form.name?.length) {
      ;(this.$el as HTMLElement).focus()
    }
  }
}
